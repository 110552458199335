import React from 'react'

import Banner from '../components/banner'
import ArticleCard from '../components/cards/ArticleCard'
import Layout from '../components/layout'

export default function Inheritance(): JSX.Element {
  return (
    <Layout>
      <div id="basics">
        <Banner bannerImage="Article" bannerText="Genetic Inheritance" textColor="black" />

        <div id="articleContainer">
          <h4 className="center">
            In this section we broke down some of the essential information needed to understand the
            mechanism of genetics. These bullets break down the basics!
          </h4>

          <hr />

          <ArticleCard
            title="What Exactly Is Genetic Inheritance?"
            summary="Your hair, eyes, and facial features all seem to resemble your parents, but why? This is genetic inheritance at work, which is how genetic material is passed down from generation to generation. Here is some of the necessary information broken down:"
            linkText="Read More!"
            linkAddr="/inheritance/intro"
            image="basics/MagnifyingGlass"
          />

          <ArticleCard
            title="So, How Can I Determine My Chance of Inheriting a Genetic Disease?"
            summary="Well, when a particular disease is diagnosed in a family, it is natural to feel eager to find out if you, too, are at risk of inheriting a specific genetic illness. Especially as a teen, it is customary to be confused and stressed about several things, and worrying about carrying a potential disease can be troublesome."
            linkText="Read More!"
            linkAddr="/inheritance/inheritance"
            image="basics/Pedigree"
          />

          <ArticleCard
            title="So, What Does the Environment Have to Do With Genetics?"
            summary="Everything around you has an impact on your genes and how they are expressed. The amount of oxygen or even radiation around your house can alter the way your genes are expressed."
            linkText="Read More!"
            linkAddr="/inheritance/environment"
            image="basics/Pills"
          />
        </div>
      </div>
    </Layout>
  )
}
